<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.591 11.091C11.0129 10.669 11.25 10.0967 11.25 9.5C11.25 8.90326 11.0129 8.33097 10.591 7.90901C10.169 7.48705 9.59674 7.25 9 7.25C8.40326 7.25 7.83097 7.48705 7.40901 7.90901C6.98705 8.33097 6.75 8.90326 6.75 9.5C6.75 10.0967 6.98705 10.669 7.40901 11.091C7.83097 11.5129 8.40326 11.75 9 11.75C9.59674 11.75 10.169 11.5129 10.591 11.091Z"
      stroke="#3F3F46"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.84375 9.5C2.79925 6.45725 5.6425 4.25 9.00025 4.25C12.3587 4.25 15.2012 6.45725 16.1567 9.5C15.2012 12.5428 12.3587 14.75 9.00025 14.75C5.6425 14.75 2.79925 12.5428 1.84375 9.5Z"
      stroke="#3F3F46"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
