<script setup lang="ts">
  import MenuItems from './MenuItems.vue';

  const route = useRoute();

  const selectedKeys = computed(() => [
    route.path === '/'
      ? 'dashboard'
      : route.path.startsWith('/donors') || route.path.startsWith('/vendors')
        ? 'contacts'
        : route.path.slice(1)
  ]);
</script>

<template>
  <div class="flex items-center pl-6 pr-4 border-b border-gray-300 shadow-sm sticky top-0 z-[51] bg-white">
    <div class="bg-blue-900 rounded p-1">
      <div class="border hover:cursor-default border-white flex justify-center items-center px-2 py-0.5 h-fit">
        <span class="text-white text-sm font-medium">ERP4</span>
        <span class="text-[#d7dd30] text-sm font-normal">Impact</span>
      </div>
    </div>

    <a-menu
      mode="horizontal"
      class="w-full flex items-center text-zinc-500 font-medium !border-0"
      :selected-keys="selectedKeys"
    >
      <MenuItems />
    </a-menu>
  </div>
</template>
