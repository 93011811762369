<script setup lang="tsx">
  import { InformationCircleIcon } from '@heroicons/vue/solid';
  import RedCrossIcon from '~/assets/Icons/RedCrossIcon.vue';
  import { post } from '~/services/donationScreen';
  import type { EmptyResponse, ResponseStatus } from '~/types';
  import { checkPermission } from '~/utils/auth/buttonController';

  const { onClose, identifier, modalFor } = defineProps<{
    onClose: () => void;
    identifier: string | null;
    modalFor: string | null;
  }>();

  const route = useRoute();

  const emailDetails = ref({
    subject: '',
    to: '',
    body: ''
  });

  const responseStatus = ref<ResponseStatus>('idle');

  const sendEmail = async () => {
    responseStatus.value = 'pending';
    try {
      if (route.query.tab === 'inKindDonation') {
        const response = await post<EmptyResponse>(`v1/email_receipts_dik/${identifier}`, {
          data: emailDetails.value
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
        } else {
          responseStatus.value = 'error';
        }
      } else {
        const response = await post<EmptyResponse>(`v1/email_receipts/${identifier}`, {
          data: emailDetails.value
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
        } else {
          responseStatus.value = 'error';
        }
      }
      onClose();
    } catch (error) {
      responseStatus.value = 'error';
      console.error('Error sending email', error);
    }
  };
</script>

<template>
  <a-modal
    centered
    width="658px"
    title="Email Donation"
    :open="modalFor"
    class="receipt-modal account-info-modal"
    @cancel="onClose"
  >
    <template #footer>
      <div
        v-if="checkPermission(route.path, 'mailActionButton')"
        class="flex gap-1 w-full justify-end border-t border-gray-300 p-3"
      >
        <a-button
          key="back"
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5 shadow-[0_1px_2px_0px_##0000000D]"
          @click="onClose"
          >Cancle</a-button
        >
        <a-button
          key="submit"
          class="px-3 border border-blue-700 rounded py-1.5 flex items-center justify-center text-sm font-medium shadow-[0_1px_2px_0px_##0000000D]"
          :loading="responseStatus === 'pending'"
          type="primary"
          @click="sendEmail"
          >Send Email</a-button
        >
      </div>
    </template>
    <div v-if="checkPermission(route.path, 'mailActionButton')" class="flex flex-col gap-2.5 w-full">
      <div class="flex gap-2.5 items-center">
        <p class="text-zinc-600 text-sm font-medium min-w-[183px]">To Email</p>
        <input v-model="emailDetails.to" type="email" class="border px-2 py-1.5 w-full border-zinc-200 rounded p-2" />
      </div>
      <div class="flex gap-2.5 items-center">
        <p class="text-zinc-600 text-sm font-medium min-w-[183px]">Subject</p>
        <input
          v-model="emailDetails.subject"
          type="text"
          class="border px-2 py-1.5 w-full border-zinc-200 rounded p-2"
        />
      </div>
      <div class="flex gap-2.5 items-start">
        <p class="text-zinc-600 text-sm font-medium min-w-[183px]">Body</p>
        <textarea v-model="emailDetails.body" class="border px-2 py-1.5 w-full border-zinc-200 rounded p-2 h-[228px]" />
      </div>
      <div class="flex w-full items-center justify-center px-3 py-2 bg-zinc-100 rounded-lg gap-2.5">
        <InformationCircleIcon class="w-4 h-4 text-[#9CA3AF]" />
        <p class="text-zinc-700 text- font-medium">Receipt will be attached as a pdf along with this email</p>
      </div>
    </div>
    <div v-else class="w-full h-full justify-center items-center flex flex-col gap-2 pt-4">
      <RedCrossIcon />
      <p class="text-sm text-zinc-500">You are not authorized to email receipts.</p>
      <a-button class="text-sm text-blue-700 border-none bg-transparent p-0 w-fit h-fit shadow-none" @click="onClose"
        >Go to {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} List</a-button
      >
    </div>
  </a-modal>
</template>

<style>
  @import '~/assets/css/modal.css';

  .receipt-modal .ant-modal-body {
    padding: 12px !important;
  }
</style>
