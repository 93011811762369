<script setup lang="tsx">
  import RedCrossIcon from '~/assets/Icons/RedCrossIcon.vue';
  import {
    getCurrentYear,
    getEndOfDay,
    getMonthName,
    getStartOfDayISOString,
    getToday,
    type dateTimeType
  } from '~/functions/dateTimeUtilities';
  import { post } from '~/services/donationScreen';
  import type { EmptyResponse, ResponseStatus } from '~/types';
  import { checkPermission } from '~/utils/auth/buttonController';

  const { onClose, identifier, modalFor } = defineProps<{
    onClose: () => void;
    identifier: string | null;
    modalFor: string | null;
  }>();

  const route = useRoute();

  const selectedDate = ref<dateTimeType>(getToday());

  const disabledDate = (current: dateTimeType) => {
    return current && current > getEndOfDay();
  };

  const comments = ref<string>('');

  const onSelect = (value: dateTimeType) => {
    selectedDate.value = value;
  };

  const yearOptions = computed(() => {
    const currentYear = getCurrentYear();
    const startYear = currentYear - 20;
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i).filter(
      year => year <= currentYear
    );
  });

  const monthOptions = computed(() => {
    return Array.from({ length: 12 }, (_, i) => ({
      value: i,
      label: getMonthName(i)
    }));
  });

  const onYearChange = (newYear: number) => {
    selectedDate.value = selectedDate.value.year(newYear);
  };

  const onMonthChange = (newMonth: number) => {
    selectedDate.value = selectedDate.value.month(newMonth);
  };

  const requestBody = computed(() => ({
    last_emailed_date: getStartOfDayISOString(selectedDate.value),
    last_emailed_comment: comments.value
  }));

  const responseStatus = ref<ResponseStatus>('idle');

  const updateLastSent = async () => {
    responseStatus.value = 'pending';
    try {
      if (route.query.tab == 'inKindDonation') {
        const response = await post<EmptyResponse>(`v1/update_last_emailed_date_dik/${identifier}`, {
          data: requestBody.value
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
          openSuccessfullyUpdatedNotification('Last Send Date');
        } else {
          responseStatus.value = 'error';
          openFailedAdditionNotification('Last Send Date');
        }
      } else {
        const response = await post<EmptyResponse>(`v1/update_last_emailed_date/${identifier}`, {
          data: requestBody.value
        });
        if (response.data.status === 'success') {
          responseStatus.value = 'success';
          openSuccessfullyUpdatedNotification('Last Send Date');
        } else {
          responseStatus.value = 'error';
          openFailedAdditionNotification('Last Send Date');
        }
      }
      onClose();
    } catch (error) {
      responseStatus.value = 'error';
      console.error('Error updating', error);
    }
  };
</script>

<template>
  <a-modal
    class="calendar-modal account-info-modal"
    centered
    title="Mark Receipt as Sent"
    :open="modalFor"
    width="400px"
    @cancel="onClose"
  >
    <div v-if="checkPermission(route.path, 'editLastSentButton')" class="flex flex-col">
      <a-calendar :fullscreen="false" :value="selectedDate" :disabled-date="disabledDate" @select="onSelect">
        <template #headerRender="{ value: current }">
          <div class="flex flex-col gap-3 py-2 px-3">
            <p class="text-zinc-600 text-sm">Set date when the receipt is sent to the donor</p>
            <div class="flex gap-2 justify-center">
              <a-select :size="'small'" class="text-sm" :value="current.year()" @change="onYearChange">
                <a-select-option v-for="y in yearOptions" :key="y" :value="y">
                  {{ y }}
                </a-select-option>
              </a-select>
              <a-select :size="'small'" :value="current.month()" style="width: 120px" @change="onMonthChange">
                <a-select-option v-for="m in monthOptions" :key="m.value" :value="m.value">
                  {{ m.label }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </template>
      </a-calendar>
      <div class="flex flex-col border-t border-zinc-200 p-3 gap-3">
        <p class="text-zinc-600 text-sm font-medium">Comments</p>
        <a-textarea
          v-model:value="comments"
          placeholder="Enter your comments here"
          :rows="4"
          class="border border-zinc-200 rounded p-2"
        />
      </div>
    </div>
    <div v-else class="w-full h-full justify-center items-center flex flex-col gap-2 pt-4">
      <RedCrossIcon />
      <p class="text-sm text-zinc-500">You are not authorized to edit last sent date.</p>
      <a-button class="text-sm text-blue-700 border-none bg-transparent p-0 w-fit h-fit shadow-none" @click="onClose"
        >Go to {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} List</a-button
      >
    </div>
    <template #footer>
      <div
        v-if="checkPermission(route.path, 'editLastSentButton')"
        class="flex rounded-b-lg gap-1 w-full justify-end border-t border-gray-300 p-3 bg-gray-50"
      >
        <a-button
          class="rounded text-sm font-medium border border-gray-300 text-gray-500 px-3 py-1.5 shadow-[0_1px_2px_0px_##0000000D]"
          @click="onClose"
        >
          Cancel
        </a-button>
        <a-button
          :loading="responseStatus === 'pending'"
          type="primary"
          class="px-3 border border-blue-700 rounded py-1.5 flex items-center justify-center text-sm font-medium shadow-[0_1px_2px_0px_##0000000D]"
          @click="updateLastSent"
        >
          Set Date
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<style>
  @import '@/assets/css/modal.css';

  .calendar-modal .ant-modal-body {
    padding: 0 !important;
  }

  .calendar-modal .ant-picker-body {
    padding: 8px !important;
  }
</style>
