<script setup lang="tsx">
  import { PencilIcon, ChevronDownIcon } from '@heroicons/vue/solid';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import { shareOnWhatsApp, viewResponseFile } from '~/functions/fileActionHandlers';
  import { get, getReceipt } from '~/services/donationScreen';
  import type { GetResponse, MonetaryDonationsDrawer, ResponseStatus } from '~/types';
  import Attachments from '../../Others/Attachments.vue';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import MailIconFilled from '~/assets/Icons/MailIconFilled.vue';
  import WhatsAppIcon from '~/assets/Icons/WhatsAppIcon.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import { checkPermission } from '~/utils/auth/buttonController';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';

  const { onClose, identifier, openModal } = defineProps<{
    onClose: () => void;
    identifier: string;
    openModal: (modalType: string, identifier: string) => void;
  }>();

  const route = useRoute();
  const data = ref<MonetaryDonationsDrawer>({} as MonetaryDonationsDrawer);
  const responseState = ref<ResponseStatus>('idle');

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<MonetaryDonationsDrawer>>(`v1/get_donation/${identifier}`);
      if (response.data.status === 'success') {
        data.value = response.data.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePrintReceipt = async () => {
    try {
      const response = await getReceipt(identifier);
      viewResponseFile(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Donations"
    :open="identifier !== null"
    width="1080"
    @close="onClose"
  >
    <template #extra>
      <div class="flex gap-2.5">
        <RoleBasedButton
          v-if="checkPermission(route.path, 'viewActionButton')"
          component-name="receiptActionButton"
          :disabled="responseState === 'pending'"
          active-class="rounded sm-medium py-1.5 px-2 flex justify-center items-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disables-class="rounded sm-medium py-1.5 px-2 flex justify-center items-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="handlePrintReceipt"
        >
          View/Print Receipt
        </RoleBasedButton>
        <a-dropdown
          v-if="checkPermission(route.path, 'viewActionButton')"
          :disabled="responseState === 'pending'"
          :placement="'bottomLeft'"
        >
          <template #overlay>
            <a-menu class="w-[155px]">
              <a-menu-item key="1" @click="openModal('emailReceipt', identifier)">
                <div class="flex items-center gap-2">
                  <MailIconFilled />
                  <p>Email</p>
                </div>
              </a-menu-item>
              <a-menu-item key="2" @click="shareOnWhatsApp(identifier)">
                <div class="flex items-center gap-2">
                  <WhatsAppIcon />
                  <p>WhatsApp</p>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
          <a-button
            class="flex items-center gap-1 rounded sm-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          >
            Send Receipt
            <ChevronDownIcon class="h-4 w-4 text-gray-500" />
          </a-button>
        </a-dropdown>
        <RoleBasedLinkButton
          v-if="checkPermission(route.path, 'viewActionButton')"
          :to="{ path: '/donors/edit-donation', query: { identifier } }"
          component-name="headerPrimaryButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Donation
        </RoleBasedLinkButton>
      </div>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="data?.selected_donation?.donor?.name"
      :sub-display="data?.selected_donation?.receipt_no"
      :side-display="formatToIndianCurrency(data?.selected_donation?.amount)"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow
            label="Donation Date"
            :value="
              data?.selected_donation?.created_date
                ? formatDate(data?.selected_donation?.created_date, 'DD/MM/YYYY')
                : '-'
            "
          />
          <InfoRow label="Mode of Payment" :value="checkValueAndLength(data?.selected_donation?.mode_of_payment)" />
          <InfoRow label="Payment Reference" :value="checkValueAndLength(data?.selected_donation?.reference_number)" />
          <InfoRow label="80G Receipt" :value="data?.selected_donation?.is_80g ? 'Yes' : 'No'" />
        </InfoSection>

        <InfoSection title="Purpose and Dimensions">
          <div class="py-2 px-3">
            <div class="w-full border-0.5 border-zinc-300 overflow-hidden rounded">
              <table class="w-full">
                <thead class="bg-zinc-100">
                  <tr>
                    <th scope="col" class="px-3 py-2 text-left sm-medium border border-zinc-200">Purpose</th>
                    <th scope="col" class="px-3 py-2 text-left sm-medium border border-zinc-200">Dimension</th>
                    <th scope="col" class="px-3 py-2 text-right sm-medium border border-zinc-200">Amount</th>
                    <th scope="col" class="px-3 py-2 text-right sm-medium border border-zinc-200">Account Info</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-zinc-300">
                  <tr
                    v-for="({ accounting_status, amount, dimension, name }, index) in data?.selected_donation?.purpose"
                    :key="index"
                  >
                    <td class="px-3 py-2 whitespace-nowrap sm-medium border border-zinc-200">
                      {{ dimension }}
                    </td>
                    <td class="px-3 py-2 whitespace-nowrap sm-medium border border-zinc-200">
                      {{ name }}
                    </td>
                    <td class="px-3 py-2 whitespace-nowrap sm-medium border border-zinc-200 text-right">
                      {{ formatToIndianCurrency(+amount) }}
                    </td>
                    <td class="px-3 py-2 whitespace-nowrap font-medium border border-zinc-200 text-right">
                      <p
                        class="text-xs"
                        :class="accounting_status === 'Info missing' ? 'text-red-700' : 'text-blue-600'"
                      >
                        {{ accounting_status }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </InfoSection>
        <InfoSection title="Donor Information">
          <InfoRow
            :label="data?.selected_donation?.donor?.pan_alternate_id?.id_type"
            :value="checkValueAndLength(data?.selected_donation?.donor?.pan_alternate_id?.id_ref)"
          />
          <InfoRow label="Email Address" :value="checkValueAndLength(data?.selected_donation?.donor?.email)" />
          <InfoRow label="Phone" :value="checkValueAndLength(data?.selected_donation?.donor?.phone)" />
          <InfoRow label="Address" :value="checkValueAndLength(data?.selected_donation?.donor?.address)" />
        </InfoSection>
        <div class="flex flex-col p-3 gap-2.5">
          <p class="font-medium text-sm text-zinc-600">Receipt Notes</p>
          <p class="font-medium text-sm">
            {{ checkValueAndLength(data?.selected_donation?.receipt_comments) }}
          </p>
        </div>
        <div class="flex flex-col p-3 gap-2.5">
          <p class="font-medium text-sm text-zinc-600">Internal Comments</p>
          <p class="font-medium text-sm">
            {{ checkValueAndLength(data?.selected_donation?.internal_comments) }}
          </p>
        </div>
      </template>
      <template #column2>
        <Attachments :items="data?.selected_donation?.attachments" :table="data?.selected_donation.table" />
        <AuditLogs :items="data?.audit_logs" />
        <AttachmentUpdates :items="data?.attachment_logs" />
      </template>
    </DrawerLayout>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
