import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexxE7jqVeAcsMeta } from "/app/pages/advances/index.vue?macro=true";
import { default as indexI08es032vOMeta } from "/app/pages/budgets/index.vue?macro=true";
import { default as add_45new_45donationM7hsgjXqmiMeta } from "/app/pages/donations/add-new-donation.vue?macro=true";
import { default as edit_45donationUBjzYjweO7Meta } from "/app/pages/donations/edit-donation.vue?macro=true";
import { default as indexWUuiwNpZZeMeta } from "/app/pages/donations/index.vue?macro=true";
import { default as indexx5kczy2fnOMeta } from "/app/pages/donors/add-new-donor/index.vue?macro=true";
import { default as indexwytB7jBwBYMeta } from "/app/pages/donors/edit-donor/index.vue?macro=true";
import { default as indextvCs4IDz35Meta } from "/app/pages/donors/index.vue?macro=true";
import { default as indexqQwwGsgaUsMeta } from "/app/pages/expenses/index.vue?macro=true";
import { default as indexbaVB2GpKMcMeta } from "/app/pages/forbidden/index.vue?macro=true";
import { default as indexU496UweDhTMeta } from "/app/pages/grants/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as indexs6x39PndESMeta } from "/app/pages/pledges/index.vue?macro=true";
import { default as index6ox58Fk0byMeta } from "/app/pages/setup/index.vue?macro=true";
import { default as indexrVEAE3qysDMeta } from "/app/pages/support/index.vue?macro=true";
import { default as indexEzEw8CXtVOMeta } from "/app/pages/utilities/index.vue?macro=true";
import { default as indexJEJnh0nyRrMeta } from "/app/pages/vendors/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "advances",
    path: "/advances",
    component: () => import("/app/pages/advances/index.vue").then(m => m.default || m)
  },
  {
    name: "budgets",
    path: "/budgets",
    component: () => import("/app/pages/budgets/index.vue").then(m => m.default || m)
  },
  {
    name: "donations-add-new-donation",
    path: "/donations/add-new-donation",
    component: () => import("/app/pages/donations/add-new-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations-edit-donation",
    path: "/donations/edit-donation",
    component: () => import("/app/pages/donations/edit-donation.vue").then(m => m.default || m)
  },
  {
    name: "donations",
    path: "/donations",
    component: () => import("/app/pages/donations/index.vue").then(m => m.default || m)
  },
  {
    name: "donors-add-new-donor",
    path: "/donors/add-new-donor",
    component: () => import("/app/pages/donors/add-new-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors-edit-donor",
    path: "/donors/edit-donor",
    component: () => import("/app/pages/donors/edit-donor/index.vue").then(m => m.default || m)
  },
  {
    name: "donors",
    path: "/donors",
    component: () => import("/app/pages/donors/index.vue").then(m => m.default || m)
  },
  {
    name: "expenses",
    path: "/expenses",
    component: () => import("/app/pages/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: "forbidden",
    path: "/forbidden",
    component: () => import("/app/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: "grants",
    path: "/grants",
    component: () => import("/app/pages/grants/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "pledges",
    path: "/pledges",
    component: () => import("/app/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/app/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "utilities",
    path: "/utilities",
    component: () => import("/app/pages/utilities/index.vue").then(m => m.default || m)
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/app/pages/vendors/index.vue").then(m => m.default || m)
  }
]