<script setup lang="ts">
  import { checkPermission } from '~/utils/auth/buttonController';
  import HeaderDisplay from './HeaderDisplay.vue';
  import LoadingState from './LoadingState.vue';
  import RedCrossIcon from '~/assets/Icons/RedCrossIcon.vue';

  const { responseState } = defineProps<{
    responseState: string;
    mainDisplay: string;
    subDisplay: string;
    sideDisplay: string;
    onClose: () => void;
  }>();

  const route = useRoute();
</script>

<template>
  <div
    v-if="checkPermission(route.path, 'viewActionButton')"
    class="flex flex-col max-h-[calc(100vh-57px)] h-full overflow-y-auto"
  >
    <HeaderDisplay :main-display="mainDisplay" :sub-display="subDisplay" :side-display="sideDisplay" />
    <div v-if="responseState === 'success'" class="flex max-h-[calc(100vh-57px)] h-full overflow-y-auto">
      <div class="flex flex-col h-full w-full">
        <slot name="column1" />
      </div>
      <div class="flex max-w-[413px] min-w-[413px] flex-col gap-6 p-3 bg-zinc-50 h-full border-l border-zinc-200">
        <slot name="column2" />
      </div>
    </div>
    <LoadingState v-else />
  </div>
  <div v-else class="w-full h-full justify-center items-center flex flex-col gap-2">
    <RedCrossIcon />
    <p class="text-sm text-zinc-500">
      {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} you are looking for
      either does not exist or is outside your permission level.
    </p>
    <a-button class="text-sm text-blue-700 border-none bg-transparent p-0 w-fit h-fit shadow-none" @click="onClose"
      >Go to {{ route.path.split('/')[1].charAt(0).toUpperCase() + route.path.split('/')[1].slice(1) }} List</a-button
    >
  </div>
</template>
