export interface LoginResponse {
  first_name: string;
  last_name: string;
  role: string;
  user_email: string;
}

export enum Role {
  Admin = 'admin',
  DonationManager = 'donation_manager',
  DonorManager = 'donor_manager',
  ReadOnly = 'read-only',
  Auditor = 'auditor',
  DonorAuditor = 'donor_auditor',
  GrantReportManager = 'grant_report_manager',
  ExpenseReportManager = 'expense_report_manager',
  BudgetReportManager = 'budget_report_manager',
  DonorReportManager = 'donor_report_manager',
  ExpenseApprover = '_expense_approver',
  ExpenseAdmin = '_expense_admin',
  ExpenseSubmitter = 'expense_submitter',
  BudgetApprover = 'budget_approver',
  BudgetMember = 'budget_member',
  GrantSubmitter = 'grant_submitter',
  GrantMember = 'grant_member',
  UtilitiesAccess = 'utilities_access'
}

export interface PermissionConfig {
  [route: string]: {
    [action: string]: Role[];
  };
}
