export const variableMap: { [key: string]: string } = {
  purpose: 'Purpose',
  line_items: 'Line Items',
  enable_grants: 'Enable Grants',
  is_fcra: 'FCRA',
  address: 'Address',
  date: 'Date',
  internal_comments: 'Internal Comments',
  amount: 'Amount',
  description: 'Description',
  dimension: 'Dimenstion',
  name: 'Name',
  donor_type: 'Donor Type',
  pan: 'PAN',
  isd_code: 'ISD Code',
  accounting_date: 'Accounting Date'
};
