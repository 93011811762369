import type { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { captureException } from '@sentry/vue';

// Function to get the API instance
const getApi = (): AxiosInstance => {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$api;
};

// Error handling function
const handleError = (error: any): any => {
  console.error('Auth API request failed:', error);
  captureException(error);
};

// POST request function for authentication
export const postAuth = async <T>(endpoint: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.post(
      endpoint,
      {
        ...config
      },
      {
        withCredentials: true
      }
    );
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};

// GET request function for authentication
export const getAuth = async <T>(endpoint: string): Promise<AxiosResponse<T>> => {
  try {
    const api = getApi();
    const response: AxiosResponse<T> = await api.get(endpoint, {
      withCredentials: true
    });
    return {
      headers: response.headers,
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config: response.config
    };
  } catch (error) {
    return handleError(error);
  }
};
