import axios from 'axios';
export default defineNuxtPlugin(() => {
  const defaultUrl = 'https://api.erp4impact.com/';

  const api = axios.create({
    baseURL: defaultUrl,
    headers: {
      common: {}
    }
  });
  return {
    provide: {
      api: api
    }
  };
});
