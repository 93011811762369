<script setup lang="ts">
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import type { SelectedDonation } from '~/types';

  const props = defineProps<{
    lineItems: SelectedDonation['line_items'];
    accountingStatus: SelectedDonation['purpose'][0]['accounting_status'];
  }>();
</script>

<template>
  <div class="py-2 px-3">
    <div class="w-full border-0.5 border-zinc-300 overflow-hidden rounded">
      <table class="w-full">
        <thead class="bg-zinc-100">
          <tr>
            <th scope="col" class="px-3 py-2 text-left text-sm font-medium border border-zinc-200">Sr</th>
            <th scope="col" class="px-3 py-2 text-left text-sm font-medium border border-zinc-200">Stock Item</th>
            <th scope="col" class="px-3 py-2 text-left text-sm font-medium border border-zinc-200">Qty</th>
            <th scope="col" class="px-3 py-2 text-right text-sm font-medium border border-zinc-200">Est Price</th>
            <th scope="col" class="px-3 py-2 text-right text-sm font-medium border border-zinc-200">Price</th>
            <th scope="col" class="px-3 py-2 text-right text-sm font-medium border border-zinc-200">A/c Info</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-zinc-300">
          <tr v-for="({ si_amount, si_qty, si_rate, si_text }, index) in props.lineItems" :key="index">
            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium border border-zinc-200">
              {{ index + 1 }}
            </td>
            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium border border-zinc-200">
              {{ si_text }}
            </td>
            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium border border-zinc-200">
              {{ si_qty }}
            </td>
            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium border border-zinc-200 text-right">
              {{ formatToIndianCurrency(+si_rate) }}
            </td>
            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium border border-zinc-200 text-right">
              {{ formatToIndianCurrency(+si_amount) }}
            </td>
            <td class="px-3 py-2 whitespace-nowrap font-medium border border-zinc-200 text-right">
              <p class="text-xs" :class="props.accountingStatus === undefined ? 'text-red-700' : 'text-blue-600'">
                {{ props.accountingStatus ?? 'Info Missing' }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
