<script setup lang="ts">
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import SupportIcon from '~/assets/Icons/SupportIcon.vue';
  import { getAuth } from '~/services/auth';
  import type { EmptyResponse } from '~/types';
  import { canAccess } from '~/utils/auth/menuItemController';
  const { logout } = useAuth();

  const handleLogout = async () => {
    const response = await getAuth<EmptyResponse>('/v1/logout');
    if (response.data.status === 'success') {
      logout();
      navigateTo('/login');
    }
  };
</script>

<template>
  <a-menu-item v-if="canAccess('dashboard')" key="dashboard" class="py-1">
    <nuxt-link to="/">Dashboard</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('expenses')" key="expenses" class="py-1">
    <nuxt-link to="/expenses">Expenses</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('advances')" key="advances" class="py-1">
    <nuxt-link to="/advances">Advances</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('contacts')" key="contacts" class="py-1">
    <a-dropdown>
      <a class="ant-dropdown-link">
        <div class="flex gap-1 items-center">
          <p>Contacts</p>
          <ChevronDownIcon class="h-5 w-5 text-zinc-500" />
        </div>
      </a>
      <template #overlay>
        <a-menu class="w-[150px] !rounded-t-none !mt-1">
          <a-menu-item>
            <nuxt-link to="/donors" class="sm-medium">Donors</nuxt-link>
          </a-menu-item>
          <a-menu-item>
            <nuxt-link to="/vendors" class="sm-medium">Vendors</nuxt-link>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-menu-item>
  <a-menu-item v-if="canAccess('donations')" key="donations" class="py-1">
    <nuxt-link to="/donations">Donations</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('grants')" key="grants" class="py-1">
    <nuxt-link to="/grants">Grants</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('pledges')" key="pledges" class="py-1">
    <nuxt-link to="/pledges">Pledges</nuxt-link>
  </a-menu-item>
  <a-menu-item v-if="canAccess('budgets')" key="budgets" class="py-1">
    <nuxt-link to="/budgets">Budgets</nuxt-link>
  </a-menu-item>
  <div class="ml-auto">
    <a-menu-item v-if="canAccess('utilities')" key="utilities" class="py-1">
      <nuxt-link to="/utilities">Utilities</nuxt-link>
    </a-menu-item>
    <a-menu-item v-if="canAccess('setup')" key="setup" class="py-1">
      <nuxt-link to="/setup">Setup</nuxt-link>
    </a-menu-item>
    <a-menu-item v-if="canAccess('admin')" key="admin" class="py-1">
      <nuxt-link to="/admin">Admin</nuxt-link>
    </a-menu-item>
    <a-menu-item key="Support" class="py-1">
      <nuxt-link to="/support" class="flex items-center justify-between gap-2">
        <SupportIcon />
        <p>Support</p>
      </nuxt-link>
    </a-menu-item>
    <a-menu-item class="py-1" @click="handleLogout">Logout</a-menu-item>
  </div>
</template>
