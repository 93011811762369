<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.00293 5.884L9.99993 9.882L17.9969 5.884C17.9673 5.37444 17.744 4.89549 17.3728 4.54523C17.0015 4.19497 16.5103 3.99991 15.9999 4H3.99993C3.48951 3.99991 2.99838 4.19497 2.62711 4.54523C2.25584 4.89549 2.03253 5.37444 2.00293 5.884Z"
      fill="currentColor"
    />
    <path
      d="M18 8.11719L10 12.1172L2 8.11719V13.9992C2 14.5296 2.21071 15.0383 2.58579 15.4134C2.96086 15.7885 3.46957 15.9992 4 15.9992H16C16.5304 15.9992 17.0391 15.7885 17.4142 15.4134C17.7893 15.0383 18 14.5296 18 13.9992V8.11719Z"
      fill="currentColor"
    />
  </svg>
</template>
