<template>
  <div class="flex flex-col max-h-[calc(100vh-59px)] h-full">
    <div
      class="flex flex-col w-full h-[81px] justify-center px-3 gap-[3px] border-b border-zinc-200 bg-gradient-to-t from-[#F6FCFF] to-[#FBFCFD]"
    >
      <div class="w-[190px] h-4 rounded bg-zinc-300 animate-pulse" />
      <div class="w-[77px] h-4 rounded bg-zinc-100 animate-pulse" />
    </div>
    <div class="flex h-full">
      <div class="flex flex-col h-full w-full p-3">
        <div class="flex gap-10">
          <div class="flex flex-col gap-6">
            <div class="w-[155px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[102px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[155px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[102px] h-4 rounded bg-zinc-200 animate-pulse" />
          </div>
          <div class="flex flex-col gap-6">
            <div class="w-[135px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[103px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[135px] h-4 rounded bg-zinc-200 animate-pulse" />
            <div class="w-[103px] h-4 rounded bg-zinc-200 animate-pulse" />
          </div>
        </div>
      </div>
      <div
        class="flex max-w-[413px] min-w-[413px] flex-col gap-6 p-6 bg-zinc-50 min-h-full h-full border-t border-l border-zinc-200"
      >
        <div class="w-[229px] h-4 rounded bg-zinc-200 animate-pulse" />
        <div class="w-[181px] h-4 rounded bg-zinc-200 animate-pulse" />
        <div class="w-[198px] h-4 rounded bg-zinc-200 animate-pulse" />
        <div class="w-[162px] h-4 rounded bg-zinc-200 animate-pulse" />
      </div>
    </div>
  </div>
</template>
