export const formatToIndianCurrency = (amount: number | null) => {
  if (amount == null) return '-';
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'symbol'
  })
    .format(amount)
    .replace(/^₹/, '₹ ');
};
