import { getAuth } from '~/services/auth';
import type { GetResponse, LoginResponse } from '~/types';

const isAuthenticated = ref<boolean>(false);
const user = ref<string | null>(null);
const role = ref<Array<string>>([]);

export const useAuth = () => {
  const checkAuth = async () => {
    try {
      const response = await getAuth<GetResponse<LoginResponse>>('/v1/get-session-data');
      setUser(response.data.data);
    } catch (err) {
      console.error('Authentication check failed:', err);
    }
  };

  const setUser = (userData: LoginResponse) => {
    user.value = userData.first_name;
    isAuthenticated.value = true;
    role.value = userData.role.split(',').map(role => role.trim());
  };

  const logout = () => {
    user.value = null;
    isAuthenticated.value = false;
    role.value = [];
  };

  return {
    isAuthenticated,
    user,
    role,
    checkAuth,
    setUser,
    logout
  };
};
