import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig();
  const router = useRouter();
  // const isDev = process.env.NODE_ENV !== 'production';

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.SENTRY_DSN_PUBLIC as string,
    integrations: [Sentry.browserTracingIntegration({ router })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
    replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
    replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
    environment: config.public.SENTRY_ENV ? (config.public.SENTRY_ENV as string) : 'development',
    debug: false
  });

  nuxtApp.vueApp.mixin({
    beforeCreate() {
      this.$sentry = Sentry;
    }
  });

  // Lazy-load the replay integration
  if (import.meta.client) {
    import('@sentry/replay').then(({ Replay }) => {
      Sentry.addIntegration(
        new Replay({
          maskAllText: false,
          blockAllMedia: false
        })
      );
    });
  }
});
