<script setup lang="ts">
  const props = defineProps<{
    title: string;
  }>();
  const activeKey = ref(['1']);
</script>

<template>
  <a-collapse v-model:active-key="activeKey" expand-icon-position="end">
    <a-collapse-panel key="1" :header="props.title">
      <slot />
    </a-collapse-panel>
  </a-collapse>
</template>
