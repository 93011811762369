import { canAccessRoute } from '~/utils/auth/routeController';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isAuthenticated, checkAuth } = useAuth();
  await checkAuth();

  if (!isAuthenticated.value) {
    if (to.path !== '/login') {
      return navigateTo('/login');
    }
  } else {
    if (to.path === '/login') {
      return navigateTo('/');
    }
    if (!canAccessRoute(to.path) && to.path !== '/forbidden') {
      return useRouter().push({
        path: '/forbidden',
        query: { from: from.path }
      });
    }
  }
});
