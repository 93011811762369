<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.5L18.5 18.5M1.5 18.5L18.5 1.5L1.5 18.5Z"
      stroke="#B91C1C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
